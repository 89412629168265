<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";

/**
 * Contacts-grid component
 */
export default {
    page: {
        title: "Template List",
        meta: [{
            name: "description",
            content: appConfig.description
        }],
    },
    components: {
        Layout,
        PageHeader,
        Multiselect
    },
    data() {
        return {
            loading: false,
            title: "Template List",
            items: [{
                    text: "Templates",
                    href: "#",
                },
                {
                    text: "Template List",
                    active: true,
                },
            ],
            templates: [],
            moreExists: true,
            nextPage: 0,
            viewtempdata: "",
            type: null,
            typeArr: [],
            temp_type: null,
            tempTypeArr: [],
            status: null,
            loadingfirst: true,
            statusArr: [{
                    key: "true",
                    value: "Active"
                },
                {
                    key: "false",
                    value: "InActive"
                },
            ],
            param: {
                type: "",
                temp_type: "",
                status: "",
            },
            isStatus: "true",
        };
    },
    mounted() {
        this.TempDetails();
        this.TypeDetails();
    },
    methods: {
        async TypeDetails() {
            var result = await ApiClass.getRequest("admin/setting/temptypes", true);
            if (result.data.status_code == 1) {
                this.typeArr = result.data.data.sendtype;
                this.tempTypeArr = result.data.data.temptype;
            }
        },
        async TempDetails(queryString = "") {
            this.loadingfirst = true;
            var result = await ApiClass.getRequest(
                "admin/template/get?per_page=10&" + queryString,
                true
            );
            if (result.data.status_code == 1) {
                this.loadingfirst = false;
                this.templates = result.data.data.data;
            }

            if (result.data.data.current_page < result.data.data.last_page) {
                this.moreExists = true;
                this.nextPage = result.data.data.current_page + 1;
            } else {
                this.moreExists = false;
            }
        },
        async load_more(queryString = "") {
            this.loading = true;

            var result = await ApiClass.getRequest(
                "admin/template/get?per_page=10&page=" +
                this.nextPage +
                "&" +
                queryString,
                true
            );
            if (result.data.data.current_page < result.data.data.last_page) {
                this.moreExists = true;
                this.nextPage = result.data.data.current_page + 1;
            } else {
                this.moreExists = false;
            }
            this.templates = [];
            result.data.data.data.forEach((element) => {
                this.templates.push(element);
            });

            this.loading = false;
        },
        viewtemp(item) {
            this.viewtempdata = item;
        },
        dlttemp(id, index) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then(async (result) => {
                if (result.value) {
                    var response = await ApiClass.deleteRequest(
                        "admin/template/delete/" + id,
                        true
                    );
                    this.templates.splice(index, 1);
                    Swal.fire("Deleted!", response.data.message, "success");
                }
            });
        },
        filter(columnVal, columnName) {
            columnVal = columnVal == null ? "" : columnVal.key;
            this.$set(this.param, columnName, columnVal);
            var queryString = Object.keys(this.param)
                .map((key) => key + "=" + this.param[key])
                .join("&");
            this.TempDetails(queryString);
        },
        remove_filter() {
            this.param = {};
            this.type = this.temp_type = this.status = "";
            this.TempDetails("");
        },
        async assignTemplate(status, id) {
            status = status == false ? 0 : 1;
            var response = await ApiClass.updateRequest(
                "admin/template/update/" + id + "/" + status,
                true
            );
            var queryString = Object.keys(this.param)
                .map((key) => key + "=" + this.param[key])
                .join("&");
            this.TempDetails(queryString);
            Swal.fire("Updated!", response.data.message, "success");
        },
    },

    // users/orders/get/1?type=buy&coin=USDT&status=placed&date=2021-10-07
};
</script>

<template>
<Layout>
    <PageHeader :title="title" :items="items" />
    <!-- <filter section> -->
    <div class="row">
        <div class="col-xl-3 col-sm-6">
            <div class="form-group">
                <p class="card-title-desc">Send Type:</p>
                <multiselect v-model="type" track-by="key" label="value" :options="typeArr" @input="filter(type, 'type')"></multiselect>
            </div>
        </div>
        <div class="col-xl-3 col-sm-6">
            <div class="form-group">
                <p class="card-title-desc">Status:</p>
                <multiselect v-model="status" track-by="key" label="value" :options="statusArr" @input="filter(status, 'status')"></multiselect>
            </div>
        </div>
        <div class="col-xl-3 col-sm-6">
            <div class="form-group">
                <p class="card-title-desc">Send Type:</p>
                <multiselect v-model="temp_type" track-by="key" label="value" :options="tempTypeArr" @input="filter(temp_type, 'temp_type')"></multiselect>
            </div>
        </div>
        <div class="col-xl-3 col-sm-6">
            <div class="form-group">
                <p class="card-title-desc">Remove Filter:</p>
                <b-button type="button" variant="primary" @click="remove_filter">Reset</b-button>
            </div>
        </div>
    </div>

    <div >
        <!-- No Result Found -->
         <div v-if="loadingfirst" class="d-flex justify-content-center align-items-center" style="height: 50vh">
            <b-spinner label="Spinning"></b-spinner>
        </div>
        <div v-else class="row mt-3">

        <div v-if="templates.length == 0" class="d-flex justify-content-center align-items-center" style="height: 50vh">
            <h1>No Result Found</h1>
        </div>
        <div v-else v-for="(rec, index) in templates" :key="index" class="col-xl-3 col-sm-6">

            <div class="card text-center">
                <div class="card-body">
                    <div class="avatar-sm mx-auto mb-4">
                        <span class="
                  avatar-title
                  rounded-circle
                  bg-soft bg-primary
                  text-primary
                  font-size-16
                ">{{ rec.type.charAt(0) }}</span>
                    </div>
                    <h5 class="font-size-15 mb-1">
                        <a href="javascript: void(0);" class="text-dark">{{
                rec.type
              }}</a>
                    </h5>
                    <p class="text-muted">{{ rec.temp_type }}</p>
                </div>
                <div class="card-footer bg-transparent border-top">
                    <div class="contact-links d-flex font-size-20">
                        <div class="flex-fill">
                            <a title="View" href="javascript: void(0);">
                                <i class="mdi mdi-compass-outline" v-b-modal.view @click="viewtemp(rec.content)"></i>
                            </a>
                        </div>
                        <div class="flex-fill">
                            <template>
                                <div class="form-switch">
                                    <input v-model="rec.status" class="form-check-input" type="checkbox" id="SwitchCheckSizemd" @input="assignTemplate(!rec.status, rec.id)" />
                                </div>
                            </template>
                        </div>
                        <div class="flex-fill">
                            <a title="Delete" href="javascript: void(0);">
                                <i class="mdi mdi-delete" @click="dlttemp(rec.id, index)"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
        <!-- end col -->
    </div>

    <!-- end row -->
    <div class="row" v-show="moreExists">
        <div class="col-12">
            <div class="text-center my-3">
                <template v-if="loading"> Loading... </template>
                <template v-else>
                    <b-button type="button" variant="primary" @click="load_more(param)">Load More</b-button>
                </template>
            </div>
        </div>
        <!-- end col-->
    </div>
    <!-- end row -->

    <!-- view model -->
    <b-modal id="view" size="lg" title="Template Viewer Section">
        <p><span v-html="viewtempdata"></span></p>
    </b-modal>
</Layout>
</template>
